<template>
  <div class="home">
    <h2>通用组件展示</h2>
    <el-divider></el-divider>
    <h3>回到顶部</h3>
    <SJXTop />
  </div>
</template>

<script>
import SJXTop from '@/components/BackTop/SJXTop.vue';

export default {
  components: {
    SJXTop,
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 20px;
  min-height: 2000px;
}
</style>
